import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import {
  InstantSearch,
  Configure,
  Index,
  Highlight,
  connectAutoComplete,
} from 'react-instantsearch-dom'

import styles from './SearchForm.module.scss'
import keys from 'constants/keys'
import { BoxIcon } from 'components/shared/Icons'

const ItemSearchBar = props => (
  <div className={styles.itemsSearchContainer}>
    <InstantSearch
      appId={keys.ALGOLIA.APP_ID}
      apiKey={keys.ALGOLIA.API_KEY}
      indexName={keys.ALGOLIA.INDEX}
      refresh={true}
    >
      <AutoComplete onQueryChange={props.onQueryChange} />
      <Configure hitsPerPage={5} />
    </InstantSearch>
  </div>
)

class ItemSuggestions extends React.Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
  }

  state = {
    value: this.props.currentRefinement,
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
    this.props.onQueryChange(newValue)
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
    this.props.onQueryChange(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.refine()
  }

  getSuggestionValue(hit) {
    return hit.name
  }

  renderSuggestion(hit) {
    return (
      <span>
        <BoxIcon className="autosuggest-icon" />
        <Highlight attribute="name" hit={hit} tagName="mark" />
      </span>
    )
  }

  renderSectionTitle(section) {
    return section.index
  }

  getSectionSuggestions(section) {
    return section.hits
  }

  render() {
    const { hits } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: "I'm looking for a ...",
      onChange: this.onChange,
      value,
    }

    return (
      <Autosuggest
        suggestions={hits}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
      />
    )
  }
}

const AutoComplete = connectAutoComplete(ItemSuggestions)

export default ItemSearchBar
