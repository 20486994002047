import React from 'react'

import styles from './QuoteSection.module.scss'

import classyEvent from '../../../../static/img/home/classy-event.jpeg'
import djEvent from '../../../../static/img/home/quote/dj.jpeg'
import shoot from '../../../../static/img/home/quote/shoot.jpeg'
import shuffleboard from '../../../../static/img/home/quote/shuffleboard.jpeg'
import Button from 'components/shared/Button'

export default () => {
  return (
    <div className={styles.quoteSection}>
      <div className={styles.container}>
        <div className={styles.image}>
          <img src={shoot} />
        </div>

        <div className={styles.content}>
          <div className={styles.header}>Planning an event?</div>
          <div className={styles.body}>
            <p>
              Let us help make your next event memorable without the hassle.
            </p>
            <p>
              Tell us what you are looking for and we'll give you
              recommendations with a competitve quote
            </p>
          </div>
          <div>
            <Button to="/quote" inverse big>
              Get a Quote
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
