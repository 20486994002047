import React, { useEffect, useRef, useState } from 'react'

import { searchStore } from '../SearchStore'
import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import { useNavbarConfig } from 'components/Navbar/NavbarConfig'
import {
  FeaturedSection,
  SuggestionSection,
  SearchSection,
  QuoteSection,
  BlogSection,
} from 'components/HomePage'

function IndexPage(props) {
  useEffect(() => {
    // reset the search store on mount
    searchStore.reset()
  }, [])
  return (
    <>
      <SearchSection />
      <SuggestionSection />
      <FeaturedSection />
      <QuoteSection />
      <BlogSection />
    </>
  )
}

export default ({ data, location }) => {
  useNavbarConfig({ overlay: true, showSearchBar: false })
  return (
    <Layout location={location}>
      <SEO />
      <IndexPage data={data} />
    </Layout>
  )
}
