import React from 'react'
import qs from 'qs'
import { Link } from 'gatsby'
import { fromJS } from 'immutable'

import styles from './SuggestionSection.module.scss'
import { searchStore } from 'SearchStore'
import { SUGGESTION_DATA } from './data.js'

export default function() {
  return (
    <div className={styles.suggestionContainer}>
      <div className={styles.sectionTitle}>Explore items by category</div>
      <div className={styles.suggestionGrid}>
        {SUGGESTION_DATA.map(suggestion => (
          <Link
            onClick={() => (
              searchStore.reset(),
              searchStore.setCategory(`type:${suggestion.category}`)
            )}
            to={`/search?include=category`}
            key={suggestion.title}
            className={styles.suggestionItem}
          >
            <img src={suggestion.image} className={styles.suggestionImg} />
            <div className={styles.suggestionTitle}>{suggestion.title}</div>
            <div className={styles.suggestionDescription}>
              {suggestion.description}
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
