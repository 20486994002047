import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

import styles from './FeaturedSection.module.scss'
import { RightChevron } from 'components/shared/Icons'
import ItemDisplay from 'components/Displays/ItemDisplay'
import SmoothSlider from 'components/shared/Slider/SmoothSlider'
import { searchStore } from 'SearchStore'

const FavoriteSlider = props => {
  return (
    <SmoothSlider>
      {props.data.allFleet.edges.map(({ node: item }) => (
        <div key={item.id} className="slider-item">
          <ItemDisplay key={item.id} product={item} showRent={false} />
        </div>
      ))}
    </SmoothSlider>
  )
}

export default class FeaturedSection extends React.Component {
  render() {
    const { featured, setFilters } = this.props
    return (
      <section className={styles.featuredSection}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>Featured Items</div>
            <div className={styles.viewAll}>
              <Link
                onClick={() => (searchStore.reset(), searchStore.setFeatured())}
                to={`/search`}
              >
                View all
                <RightChevron />
              </Link>
            </div>
          </div>
          <div className={styles.sliderWrapper}>
            <StaticQuery
              query={graphql`
                query {
                  allFleet(
                    limit: 13
                    filter: { approved: { eq: true }, featured: { eq: true } }
                  ) {
                    totalCount
                    edges {
                      node {
                        id
                        description
                        pictures
                        name
                        type
                        city
                        state
                        price {
                          day
                        }
                        user {
                          id
                          firstName
                          lastName
                          picture
                          lastName
                          rating_count
                          avg_rating
                        }
                      }
                    }
                  }
                }
              `}
              render={data => <FavoriteSlider data={data} />}
            />
          </div>
        </div>
      </section>
    )
  }
}
