import React from 'react'

import styles from './SearchSection.module.scss'
import meloInterpolation from '../../../../static/img/home/melo-interpolation.gif'
import SearchForm from './SearchForm'

export default function() {
  return (
    <section className={styles.frontWelcome}>
      <div className={styles.introSection}>
        <SearchForm />
        <div className={styles.welcomeImg}>
          <img src={meloInterpolation} height="300" />
        </div>
      </div>
    </section>
  )
}
