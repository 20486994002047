import camera from '../../../../static/img/home/camera-category.png'
import party from '../../../../static/img/home/party-category.png'
import mobility from '../../../../static/img/home/mobility-category.png'
import outdoor from '../../../../static/img/home/outdoor-category.png'
import drones from '../../../../static/img/home/drones-category.png'
import music from '../../../../static/img/home/dj-category.png'
import home from '../../../../static/img/home/home-category.png'
import sports from '../../../../static/img/home/sports-category.png'
import tools from '../../../../static/img/home/tools-category.png'

export const SUGGESTION_DATA = [
  {
    image: camera,
    title: 'Camera Gear',
    category: 'camera-equipment',
    description: 'Gather equipment for your next shoot',
  },
  {
    image: party,
    title: 'Party Equipment',
    category: 'party-events',
    description: 'Supplies for your next special occasion',
  },
  {
    image: mobility,
    title: 'Mobility',
    category: 'mobility',
    description: 'Discover a new way to get around',
  },
  {
    image: outdoor,
    title: 'Summer Fun',
    category: 'outdoor',
    description: 'Make the most of the great outdoors',
  },
  {
    image: drones,
    title: 'Drones',
    category: 'drones',
    description: 'Get a greater view from above',
  },
  {
    image: music,
    title: 'Music',
    category: 'audio-equipment',
    description: 'High quality acoustic equipment, djs, and more',
  },
  {
    image: home,
    title: 'Home',
    category: 'home',
    description: 'Home essentials for hosting guests ',
  },
  {
    image: tools,
    title: 'Tools & Landscaping',
    category: 'outdoor',
    description: 'Everything you need to get the job done',
  },
]
