import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Button from 'components/shared/Button'

import styles from './BlogSection.module.scss'

const BlogItem = props => {
  return (
    <>
      {props.data.allMdx.edges.map(({ node }) => (
        <Link
          to={`/blog${node.fields.slug}`}
          key={node.fields.slug}
          className={styles.blogItem}
        >
          <div>
            <Img
              sizes={node.frontmatter.featuredImage.childImageSharp.sizes}
              className={styles.featuredImg}
            />
            <h5 className={styles.articleTitle}>{node.frontmatter.title}</h5>
            <h7 className={styles.readMore}>Read More</h7>
          </div>
        </Link>
      ))}
    </>
  )
}

export default props => (
  <div className={styles.blogSection}>
    <div className={styles.sectionTitle}>
      <h3 className={styles.blogTitle}>Read the Blog</h3>
    </div>
    <div className={styles.blogFeaturedContainer}>
      <div className={styles.blogFeaturedItems}>
        <StaticQuery
          query={graphql`
            query {
              allMdx(limit: 3) {
                edges {
                  node {
                    excerpt
                    fields {
                      slug
                    }
                    frontmatter {
                      date(formatString: "MMMM DD, YYYY")
                      title
                      description
                      featuredImage {
                        childImageSharp {
                          sizes(maxWidth: 630) {
                            ...GatsbyImageSharpSizes
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => <BlogItem data={data} {...props} />}
        />
      </div>
    </div>
    <div className={styles.moreBtnContainer}>
      <Button big to="/blog">
        View More Posts
      </Button>
    </div>
  </div>
)
