import React from 'react'
import { Link } from 'gatsby'
import { InstantSearch } from 'react-instantsearch-dom'
import Place from 'react-algolia-places'
import qs from 'qs'
import 'instantsearch.css/themes/algolia.css'

import styles from './SearchForm.module.scss'
import {
  MagGlass,
  PinIcon,
  CalendarIcon2,
  TypeIcon,
  DeliveryIcon,
} from 'components/shared/Icons'
import Button from 'components/shared/Button'
import DatePicker from 'components/shared/DatePicker/DatePicker'
import ItemSearch from './ItemSearch'
import keys from 'constants/keys'

export default class SearchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchState: {
        geoSearch: {
          lat: 38.894377,
          lng: -77.040798,
          radius: 50,
          name: '',
        },
        query: '',
      },
      url: '',
    }
  }

  createURL = state => {
    const url = qs.stringify(state)
    return `?${url}`
  }

  onQueryChange = newValue => {
    const searchState = { ...this.state.searchState, query: newValue }
    this.setState({ searchState, url: this.createURL(searchState) })
  }

  onPlaceChange = ({ suggestion }) => {
    const searchState = {
      ...this.state.searchState,
      geoSearch: {
        ...this.state.searchState.geoSearch,
        ...suggestion.latlng,
        name: suggestion.value,
      },
    }
    this.setState({ searchState, url: this.createURL(searchState) })
  }

  render() {
    return (
      <div className={styles.searchContainer}>
        <form>
          <h2 className={styles.introHeader}>Rentals for every occassion</h2>
          <p className={styles.introSubheader}>
            Book unique experiences from an eclectic collection of bikes,
            drones, cameras, tents and more.
          </p>
          <div className={styles.homeSearchContainer}>
            <div
              className={[
                styles.homeSearchSubcontainer,
                styles.searchTerm,
              ].join(' ')}
            >
              <MagGlass className={styles.homeSearchIcon} />
              <ItemSearch
                onQueryChange={this.onQueryChange}
                currentRefinement={this.state.searchState.query}
              />
            </div>
          </div>
          <div className={styles.homeSearchTagContainer}>
            <div
              className={[styles.homeSearchSubcontainer, styles.location].join(
                ' '
              )}
            >
              <PinIcon className={styles.homeSearchIcon} />
              <div className={styles.placesInputContainer}>
                {typeof window !== 'undefined' && (
                  <InstantSearch
                    appId={keys.ALGOLIA.APP_ID}
                    apiKey={keys.ALGOLIA.API_KEY}
                    indexName={keys.ALGOLIA.INDEX}
                    refresh={true}
                    onSearchStateChange={this.onSearchStateChange}
                    searchState={this.state.searchState}
                  >
                    <Place
                      onChange={this.onPlaceChange}
                      placeholder="Where?"
                      type="city"
                    />
                  </InstantSearch>
                )}
              </div>
            </div>
            <Button
              big
              full
              accent
              className={styles.submitBtn}
              to={`/search${this.state.url}`}
            >
              Search
            </Button>
          </div>
          <div className={styles.quoteContainer}>
            <Link to="/quote">or request a quote</Link>
          </div>
        </form>
      </div>
    )
  }
}
